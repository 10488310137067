.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1040;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background-color: #fff;
    outline: 1px solid rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
  }

  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none;
    }
  }

  .offcanvas-header {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem;
  }

  .offcanvas-header .close {
    padding: 0.5rem 0.5rem;
    margin: -1rem -1rem -1rem auto;
  }

  .offcanvas-body {
    padding: 1rem 1rem;
    overflow-y: auto;
  }

  .offcanvas-left {
    top: 0;
    left: 0;
    width: 400px;
    transform: translateX(-100%);
  }

  .offcanvas-right {
    top: 0;
    right: 0;
    width: 400px;
    transform: translateX(100%);
  }

  .offcanvas-bottom {
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    transform: translateY(100%);
  }

  .offcanvas.show {
    transform: none;
  }

  .offcanvas-freeze {
    overflow: hidden;
  }

  .offcanvas-backdrop::before {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1039;
    width: 100vw;
    height: 100vh;
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
  }